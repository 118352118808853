import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import type { FlagConfiguration } from '@atlassian/jira-flags/src/services/types.tsx';
import messages from '../../../common/messages.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

type Props = {
	objectCount?: string;
	action: TaskOperationType;
};

export const showErrorFlag =
	({ objectCount, action }: Props): CmdbAsyncActionStoreAction<void> =>
	(_, { showFlag, formatMessage }) => {
		const baseFlag: Pick<FlagConfiguration, 'messageId' | 'messageType' | 'type' | 'description'> =
			{
				messageId:
					'servicedesk-cmdb-async-actions.controllers.actions.show-error-flag.base-flag.error',
				messageType: 'transactional',
				type: 'error',
				description: formatMessage(messages.submitErrorFlagDescription),
			};
		matchTaskOperationType(action, {
			BulkExport: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitErrorFlagTitleBulkExport, { objectCount }),
				}),
			BulkQrCode: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitErrorFlagTitleBulkQrCode, { objectCount }),
				}),
			BulkEdit: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitErrorFlagTitleBulkEdit, { objectCount }),
				}),
			BulkDelete: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitErrorFlagTitleBulkDelete, { objectCount }),
				}),
			SetAttributeAsUnique: () => undefined,
			SetAttributeAsNonUnique: () => undefined,
			SetAttributeAsLabel: () => undefined,
		});
	};
