import React, { type SyntheticEvent, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { DropdownItem as MeatballsMenuItem } from '@atlaskit/dropdown-menu';
import LockIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SchemaIcon } from '@atlassian/jira-servicedesk-insight-glyphs/src/index.tsx';
import type { ObjectSchema } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-schema.tsx';
import { MeatballsMenu } from '@atlassian/jira-servicedesk-insight-shared-ui/src/ui/meatballs-menu/main.tsx';
import { getCmdbAnalyticAttributesFromSchema } from '@atlassian/jira-servicedesk-cmdb-analytics/src/index.tsx';
import {
	createObjectSchemaUrl,
	createObjectSchemaConfigUrl,
} from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { FocusableTooltip } from './focusable-tooltip/index.tsx';
import { messages } from './messages.tsx';
import type { Props } from './types.tsx';

type IconAndMenuProps = {
	schema: ObjectSchema;
};

const IconAndMenu = ({ schema }: IconAndMenuProps) => {
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (schema.isRestricted === true) {
		const content = formatMessage(messages.tooltipRestrictedSchema);
		return (
			<FocusableTooltip
				content={content}
				position="top"
				testId="servicedesk-insight-schema-card.ui.restricted-tooltip"
			>
				<IconWrapper>
					<LockIcon LEGACY_size="medium" spacing="spacious" label={content} />
				</IconWrapper>
			</FocusableTooltip>
		);
	}

	if (schema.canManage === false) {
		const content = formatMessage(messages.tooltipDisabled);
		return (
			<FocusableTooltip content={content} position="top">
				<IconWrapper isDisabled>
					<MoreIcon LEGACY_size="medium" spacing="spacious" label={content} />
				</IconWrapper>
			</FocusableTooltip>
		);
	}

	return (
		<MeatballsMenu
			shouldRenderToParent
			testId={`servicedesk-insight-schema-card.ui.meatballs-menu-${schema.id}`}
			moreIconLabel={`${schema.name} ${formatMessage(messages.more)}`}
		>
			<MeatballsMenuItem
				href={createObjectSchemaConfigUrl(schema.id)}
				onClick={(event) => {
					push(createObjectSchemaConfigUrl(schema.id));
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'configureInsightSchema');
					event.preventDefault();
				}}
			>
				{formatMessage(fg('assets_as_an_app_v2') ? messages.settings : messages.configuration)}
			</MeatballsMenuItem>
		</MeatballsMenu>
	);
};

const SchemaTooltipTag = forwardRef<HTMLDivElement>((props, ref) => (
	<StyledTooltipTag ref={ref} {...props} />
));

export const SchemaCard = (props: Props) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { schema } = props;
	const { id } = schema;

	return (
		<Container>
			<StyledLink
				data-testId="servicedesk-insight-landing-insight-schema-card.styled-link"
				href={createObjectSchemaUrl(id)}
				onClick={(event: SyntheticEvent<HTMLAnchorElement>) => {
					push(createObjectSchemaUrl(id));
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'card clicked',
						'insightObjectSchemaCard',
						// the service schema will always have isRestricted = true (and isRestricted is always false for Insight schemas)
						getCmdbAnalyticAttributesFromSchema(schema),
					);
					event.preventDefault();
				}}
			>
				<SchemaIcon label="" size="medium" />
				<CardText>
					<NameAndKey>
						{/* @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'keyof IntrinsicElements | ComponentType<AllHTMLAttributes<HTMLElement> & { ref: Ref<HTMLElement>; }> | undefined'. */}
						<Tooltip content={schema.name} tag={SchemaTooltipTag} position="top">
							<SchemaName>{schema.name}</SchemaName>
						</Tooltip>
						<span>({schema.objectSchemaKey})</span>
					</NameAndKey>
					<ObjectCount>
						{formatMessage(messages.objects, {
							objectNumber: schema.objectCount,
						})}
					</ObjectCount>
				</CardText>
			</StyledLink>
			<IconAndMenu schema={schema} />
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	boxShadow: token('elevation.shadow.raised'),
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${4 * gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	backgroundColor: token('elevation.surface.raised'),
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardText = styled.div({
	minWidth: 0,
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	gap: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectCount = styled.span({
	marginLeft: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameAndKey = styled.div({
	minWidth: 0,
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipTag = styled.div({
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SchemaName = styled.div({
	fontWeight: token('font.weight.semibold'),
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span<{ isDisabled?: boolean }>({
	alignItems: 'center',
	display: 'flex',
	paddingTop: 0,
	paddingRight: token('space.050'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isDisabled ? token('color.icon.disabled') : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.isDisabled ? 'not-allowed' : 'inherit'),
});

// See https://css-tricks.com/flexbox-truncated-text/ for why `min-width: 0` is required in nested elements
// Use the <a> tag instead of <Link> here because the page load event will not get triggered if we use the <Link> component
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLink = styled.a({
	minWidth: 0,
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	cursor: 'pointer',
	gap: token('space.100'),

	color: token('color.text.subtle'),
	'&:hover': {
		color: token('color.text.subtle'),
		textDecoration: 'none',
	},
});
StyledLink.displayName = 'StyledLink';
