import React, { useCallback, useEffect, useState } from 'react';

import { JiraCommonFlag } from '@atlassian/jira-flags/src/common/ui/components/common-flag-v2';
import { useIntl } from '@atlassian/jira-intl';
import { useFlagsService } from '@atlassian/jira-flags';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useShouldShowChangeNotification } from '../utils/index.tsx';
import type { ChangeNotificationProps } from '../../common/types.tsx';
import {
	CBP_CHANGE_NOTIFICATIONS_FLAG,
	CBP_CHANGE_NOTIFICATIONS_FLAG_DISMISS_KEY,
	CBP_CHANGE_NOTIFICATIONS_LINK,
	LOCAL_STORAGE,
} from '../../common/constants.tsx';
import messages from './messages.tsx';

const FLAG_ID =
	'servicedesk-feature-usage-common.ui.change-notification-flag.jira-common-flag' as const;

export const ChangeNotifiacationFlag = (props: ChangeNotificationProps) => {
	const { shouldShowFlag, cbpEnforcementDaysRemaining } = useShouldShowChangeNotification(props);
	const { showFlag, dismissFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const [isDismissed, setIsDismissed] = useState(
		LOCAL_STORAGE.get(CBP_CHANGE_NOTIFICATIONS_FLAG_DISMISS_KEY),
	);
	const onDismissed = useCallback(() => {
		setIsDismissed(true);
		LOCAL_STORAGE.set(CBP_CHANGE_NOTIFICATIONS_FLAG_DISMISS_KEY, true);
		dismissFlag(FLAG_ID);
	}, [dismissFlag]);

	const onTellMeMore = useCallback((_: unknown, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'changeNotificationFlagTellMeMoreClicked');
	}, []);

	useEffect(() => {
		!isDismissed &&
			shouldShowFlag &&
			showFlag({
				id: FLAG_ID,
				render: () => (
					<JiraCommonFlag
						messageId="servicedesk-feature-usage-common.ui.change-notification-flag.jira-common-flag"
						appearance="info"
						messageType="engagement"
						flagType="info"
						id={FLAG_ID}
						title={formatMessage(messages.changeNotificationFlagTitle, {
							number: cbpEnforcementDaysRemaining,
						})}
						description={formatMessage(messages.changeNotificationFlagContent)}
						actions={[
							{
								content: formatMessage(messages.tellMeMore),
								href: CBP_CHANGE_NOTIFICATIONS_LINK,
								target: '_blank',
								onClick: onTellMeMore,
							},
							{
								content: formatMessage(messages.dismissFlag),
								onClick: onDismissed,
							},
						]}
					/>
				),
			});
	}, [
		showFlag,
		dismissFlag,
		formatMessage,
		shouldShowFlag,
		cbpEnforcementDaysRemaining,
		onDismissed,
		onTellMeMore,
		isDismissed,
	]);

	return null;
};

export const ChangeNotificationFlagWithErrorBoundary = (props: ChangeNotificationProps) => (
	<JSErrorBoundary
		id="cbpChangeNotificationFlag"
		packageName="servicedeskFeatureUsageCommon"
		teamName="Sequoia"
		fallback="flag"
	>
		<ContextualAnalyticsData sourceName={CBP_CHANGE_NOTIFICATIONS_FLAG} sourceType={MODAL}>
			<FireScreenAnalytics />
			<ChangeNotifiacationFlag {...props} />
		</ContextualAnalyticsData>
	</JSErrorBoundary>
);
