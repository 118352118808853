import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeNotificationFlagTitle: {
		id: 'servicedesk-feature-usage-common.change-notification-flag.title',
		defaultMessage: '{number} days until new pricing starts',
		description:
			'This is a flag title shown to the jira admins to inform them about the upcoming cbp enforcement',
	},
	changeNotificationFlagContent: {
		id: 'servicedesk-feature-usage-common.change-notification-flag.content',
		defaultMessage: 'Assets and the virtual service agent will move to consumption-based pricing.',
		description:
			'This is a flag message shown to the jira admins to inform them about the upcoming cbp enforcement',
	},
	tellMeMore: {
		id: 'servicedesk-feature-usage-common.change-notification-flag.tell-me-more',
		defaultMessage: 'Tell me more',
		description:
			'Button label text describing the link to know more about the upcoming cbp enforcement',
	},
	dismissFlag: {
		id: 'servicedesk-feature-usage-common.change-notification-flag.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Button label text describing dismiss flag button',
	},
});
