import type { ObjectAttributeType } from './attribute-types.tsx';
import type { CmdbBulkEditOperationType } from './cmdb-bulk-edit-modal.tsx';
import type { CmdbMediaClientConfig, CmdbObject } from './cmdb-object.tsx';
import type { ObjectType } from './object-type.tsx';

type Modify<T, R> = Omit<T, keyof R> & R;

// Bulk Object tasks
export const OPERATION_TYPE_BULK_EDIT = 'BulkEdit';
export const OPERATION_TYPE_BULK_DELETE = 'BulkDelete';
export const OPERATION_TYPE_BULK_EXPORT = 'BulkExport';
export const OPERATION_TYPE_BULK_QR_CODE = 'BulkQrCode';

export const OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE = 'SetAttributeAsUnique';
export const OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE = 'SetAttributeAsNonUnique';
export const OPERATION_TYPE_ATTRIBUTE_SET_LABEL = 'SetAttributeAsLabel';

export const STATUS_QUEUED = 'Queued';
export const STATUS_IN_PROGRESS = 'InProgress';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_ARCHIVED = 'Archived';

type FinishedStatus = typeof STATUS_COMPLETED | typeof STATUS_ARCHIVED;

export type ObjectTaskOperationType =
	| typeof OPERATION_TYPE_BULK_EDIT
	| typeof OPERATION_TYPE_BULK_DELETE
	| typeof OPERATION_TYPE_BULK_EXPORT
	| typeof OPERATION_TYPE_BULK_QR_CODE;

export type AttributeTaskOperationType =
	| typeof OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE
	| typeof OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE
	| typeof OPERATION_TYPE_ATTRIBUTE_SET_LABEL;

export type TaskOperationType = ObjectTaskOperationType | AttributeTaskOperationType;

export type TaskStatus =
	| typeof STATUS_QUEUED
	| typeof STATUS_IN_PROGRESS
	| typeof STATUS_COMPLETED
	| typeof STATUS_ARCHIVED;

export type TaskLink = {
	rel: string;
	href: string;
	type: string;
};

export type TaskId = string;

export const toTaskId = (id: string): TaskId => id;

type BaseTask = {
	taskId: TaskId;
	links?: TaskLink[];
};

type ErroneousObjectsMap = {
	[OPERATION_TYPE_BULK_EDIT]: BulkEditErroneousObject[];
};

type HasMediaClientConfig = typeof OPERATION_TYPE_BULK_EXPORT | typeof OPERATION_TYPE_BULK_QR_CODE;

type OperationType<T> = {
	operationType: T;
};

type Status<S> = {
	status: S;
};

type ResultPayload<R> = {
	resultPayload: R;
};

type ErroneousObjects<E> = {
	erroneousObjects: E;
};

type TaskResultPayload = {
	objectsProcessed: number;
	objectsEncounteredError: number;
	hasError: boolean;
};

type MediaClientConfig = {
	mediaClientConfig?: CmdbMediaClientConfig;
};

type AttributePayload = {
	failureReason?: string;
	attributeName: string;
	objectType: Pick<ObjectType, 'id' | 'name' | 'objectSchemaId'>;
};

type ObjectTaskResultPayload<
	T extends ObjectTaskOperationType,
	HasErroneousObjects extends boolean = false,
> = T extends keyof ErroneousObjectsMap
	? HasErroneousObjects extends true
		? TaskResultPayload & ErroneousObjects<ErroneousObjectsMap[T]>
		: TaskResultPayload
	: T extends HasMediaClientConfig
		? TaskResultPayload & MediaClientConfig
		: TaskResultPayload;

type GetObjectTask<
	OT extends ObjectTaskOperationType,
	TS extends TaskStatus | undefined = undefined,
	HasErroneousObjects extends boolean = false,
> = TS extends undefined
	? BaseTask & OperationType<OT>
	: TS extends FinishedStatus
		? BaseTask &
				OperationType<OT> &
				Status<TS> &
				ResultPayload<ObjectTaskResultPayload<OT, HasErroneousObjects>>
		: BaseTask & OperationType<OT> & Status<TS>;

type AttributeTaskResultPayload<T extends AttributeTaskOperationType> =
	T extends typeof OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE
		? TaskResultPayload & AttributePayload
		: TaskResultPayload;

type GetAttributeTask<
	AT extends AttributeTaskOperationType,
	TS extends TaskStatus | undefined = undefined,
> = TS extends undefined
	? BaseTask & OperationType<AT>
	: TS extends FinishedStatus
		? BaseTask & OperationType<AT> & Status<TS> & ResultPayload<AttributeTaskResultPayload<AT>>
		: BaseTask & OperationType<AT> & Status<TS>;

type BulkEditTask = GetObjectTask<typeof OPERATION_TYPE_BULK_EDIT>;
type BulkDeleteTask = GetObjectTask<typeof OPERATION_TYPE_BULK_DELETE>;
type BulkExportTask = GetObjectTask<typeof OPERATION_TYPE_BULK_EXPORT>;
type BulkQrCodeTask = GetObjectTask<typeof OPERATION_TYPE_BULK_QR_CODE>;

type BaseObjectTask = BulkEditTask | BulkDeleteTask | BulkExportTask | BulkQrCodeTask;

type AttributeSetUniqueTask = GetAttributeTask<typeof OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE>;
type AttributeSetNonUniqueTask = GetAttributeTask<typeof OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE>;
type AttributeSetLabelTask = GetAttributeTask<typeof OPERATION_TYPE_ATTRIBUTE_SET_LABEL>;

type BaseAttributeTask = AttributeSetUniqueTask | AttributeSetNonUniqueTask | AttributeSetLabelTask;

export type CompletedBulkEditTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EDIT,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkDeleteTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_DELETE,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkExportTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EXPORT,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkQrCodeTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_QR_CODE,
	typeof STATUS_COMPLETED
>;

export type CompletedAttributeSetUniqueTask = GetAttributeTask<
	typeof OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE,
	typeof STATUS_COMPLETED
>;

export type CompletedAttributeSetNonUniqueTask = GetAttributeTask<
	typeof OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE,
	typeof STATUS_COMPLETED
>;

export type CompletedAttributeSetLabelTask = GetAttributeTask<
	typeof OPERATION_TYPE_ATTRIBUTE_SET_LABEL,
	typeof STATUS_COMPLETED
>;

type ArchivedBulkEditTask = Modify<CompletedBulkEditTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkDeleteTask = Modify<CompletedBulkDeleteTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkExportTask = Modify<CompletedBulkExportTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkQrCodeTask = Modify<CompletedBulkQrCodeTask, Status<typeof STATUS_ARCHIVED>>;

type ArchivedAttributeSetUniqueTask = Modify<
	CompletedAttributeSetUniqueTask,
	Status<typeof STATUS_ARCHIVED>
>;
type ArchivedAttributeSetNonUniqueTask = Modify<
	CompletedAttributeSetNonUniqueTask,
	Status<typeof STATUS_ARCHIVED>
>;
type ArchivedAttributeSetLabelTask = Modify<
	CompletedAttributeSetLabelTask,
	Status<typeof STATUS_ARCHIVED>
>;

type ArchivedObjectTask =
	| ArchivedBulkEditTask
	| ArchivedBulkDeleteTask
	| ArchivedBulkExportTask
	| ArchivedBulkQrCodeTask;

type ArchivedAttributeTask =
	| ArchivedAttributeSetUniqueTask
	| ArchivedAttributeSetNonUniqueTask
	| ArchivedAttributeSetLabelTask;

export type CompletedObjectTask =
	| CompletedBulkEditTask
	| CompletedBulkDeleteTask
	| CompletedBulkExportTask
	| CompletedBulkQrCodeTask;

export type CompletedAttributeTask =
	| CompletedAttributeSetUniqueTask
	| CompletedAttributeSetNonUniqueTask
	| CompletedAttributeSetLabelTask;

type PendingObjectTask = BaseObjectTask & Status<typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS>;

export type PendingAttributeTask = BaseAttributeTask &
	Status<typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS>;

type AttributeTask = PendingAttributeTask | CompletedAttributeTask | ArchivedAttributeTask;

type ObjectTask = PendingObjectTask | CompletedObjectTask | ArchivedObjectTask;

export type ErroneousObjectDetails = Pick<CmdbObject, 'id' | 'objectKey' | 'label' | 'avatar'>;

type ErroneousObject<T> = {
	object: ErroneousObjectDetails;
	errors: T[];
};

type BulkEditError = {
	objectTypeAttribute: Pick<ObjectAttributeType, 'name'>;
	operationType: CmdbBulkEditOperationType;
	errorMessage: string;
};

type BulkEditErroneousObject = ErroneousObject<BulkEditError>;

export type CompletedBulkEditTaskWithErroneousObjects = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EDIT,
	typeof STATUS_COMPLETED,
	true
>;

export type Task = ObjectTask | AttributeTask;
export type CompletedTask = CompletedObjectTask | CompletedAttributeTask;
export type ResultsModalTask = CompletedBulkEditTaskWithErroneousObjects | CompletedBulkDeleteTask;

// REST API responses
export type BulkActionSubmitResponse = Pick<Task, 'taskId' | 'operationType'>;
export type BulkActionTasksResponse = { tasks: Task[] };
