import { useState, useCallback } from 'react';
import { graphql, fetchQuery, useRelayEnvironment } from 'react-relay';
import { getHamsProductKey } from '@atlassian/jira-growth-utils/src/services/get-hams-product-key/index.tsx';
import type {
	utilEntitlementDetailsQuery,
	utilEntitlementDetailsQuery$data,
} from '@atlassian/jira-relay/src/__generated__/utilEntitlementDetailsQuery.graphql';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type EntitlementData = {
	entitlementId: string | null;
	transactionAccountId: string | undefined | null;
};
type FetchEntitlementResponse = {
	fetch: () => void;
	data: EntitlementData | undefined;
	isLoading: boolean;
	error: Error | null;
};

export const EntitlementQuery = graphql`
	query utilEntitlementDetailsQuery($cloudId: ID!, $productKey: String!) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					id
					transactionAccount {
						key
					}
				}
			}
		}
	}
`;

export const useFetchEntitlement = (): FetchEntitlementResponse => {
	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();
	const [data, setData] = useState<EntitlementData | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const fetch = useCallback(() => {
		setIsLoading(true);
		const variables = {
			cloudId,
			productKey: getHamsProductKey(SERVICE_DESK),
		};
		fetchQuery<utilEntitlementDetailsQuery>(relayEnvironment, EntitlementQuery, variables, {
			fetchPolicy: 'store-or-network',
		}).subscribe({
			complete: () => {
				setIsLoading(false);
			},
			next: (response: utilEntitlementDetailsQuery$data) => {
				const entitlement = response?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
				if (entitlement) {
					const entitlementId = entitlement.id;
					const transactionAccountId = entitlement.transactionAccount?.key;
					setData({ entitlementId, transactionAccountId });
				} else {
					setData({ entitlementId: null, transactionAccountId: null });
				}
			},
			error: (fetchError: Error) => {
				setIsLoading(false);
				setError(fetchError);
			},
		});
	}, [cloudId, relayEnvironment]);
	return { fetch, data, isLoading, error };
};
