import type { CompletedTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskByOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import { bulkDeleteFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-delete/index.tsx';
import { bulkEditFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-edit/index.tsx';
import { bulkExportFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-export/index.tsx';
import { qrCodeFlagHandler } from '../../../common/ui/flags/completed-flag-qr-code/index.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { dismissTask } from '../dismiss-task/index.tsx';
import { attributeSetUniqueFlagHandler } from '../../../common/ui/flags/completed-flag-attribute-set-unique/index.tsx';

export const showCompletedFlags =
	(completedTasks: CompletedTask[]): CmdbAsyncActionStoreAction<void> =>
	({ dispatch }, { showFlag, dismissFlag }) => {
		completedTasks.forEach((task) => {
			// Dismiss "Action started" flag if still present
			dismissFlag(task.taskId);
			// Pass the dismiss action to the flag component
			const onDismissed = () => {
				// Dismiss the flag from the UI
				dismissFlag(task.taskId);
				// Dismiss the task on the server
				dispatch(dismissTask({ taskId: task.taskId }));
			};
			matchTaskByOperationType(task, {
				BulkEdit: (bulkEditTask) =>
					showFlag(bulkEditFlagHandler({ task: bulkEditTask, onDismissed })),
				BulkDelete: (bulkDeleteTask) =>
					showFlag(bulkDeleteFlagHandler({ task: bulkDeleteTask, onDismissed })),
				BulkExport: (bulkExportTask) =>
					showFlag(bulkExportFlagHandler({ task: bulkExportTask, onDismissed })),
				BulkQrCode: (bulkQrCodeTask) =>
					showFlag(qrCodeFlagHandler({ task: bulkQrCodeTask, onDismissed })),
				SetAttributeAsUnique: (SetAttributeAsUniqueTask) =>
					showFlag(attributeSetUniqueFlagHandler({ task: SetAttributeAsUniqueTask, onDismissed })),
				SetAttributeAsNonUnique: () => undefined,
				SetAttributeAsLabel: () => undefined,
			});
		});
	};
