import { useCallback, useEffect, useState } from 'react';
import type { RecentObjectState } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/common/types.tsx';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import type { RecentObjectsResponse } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/actions/fetch-recent-objects/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbObjectRecentUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const useRecentObjectData = () => {
	const { workspaceId } = useWorkspaceContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [recentObjectData, setRecentObjectData] = useState<RecentObjectState>({
		type: 'loading',
	});

	const fetchRecentObjects = useCallback(async () => {
		const maxResults = 20;
		try {
			if (!workspaceId) {
				return;
			}
			const response: RecentObjectsResponse = await performGetRequest(
				getCmdbObjectRecentUrl(workspaceId, { maxResults }),
			);
			setRecentObjectData({
				type: 'success',
				recentObjects: response.values,
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchObjectSchemas succeeded');
		} catch (error) {
			const typesafeError = error instanceof Error ? error : new Error('Unknown error');
			setRecentObjectData({
				type: 'error',
				error: typesafeError,
			});
			fireCmdbErrorAnalytics({
				error: typesafeError,
				meta: {
					id: 'fetchRecentObjects',
					packageName: 'jiraServicedeskInsightLandingPageStore',
					teamName: 'falcons',
				},
			});
		}
	}, [workspaceId, createAnalyticsEvent]);

	useEffect(() => {
		if (fg('fly-2773-fix-schema-landing-page-multiple-loading')) {
			fetchRecentObjects();
		}
	}, [fetchRecentObjects]);

	return recentObjectData;
};
