import { useEffect } from 'react';
import { getAdminHubUrl } from '@atlassian/jira-onboarding-quickstart-core/src/common/utils/get-admin-hub-url.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	ChangeNotificationProps,
	FeatureTypes,
	ShouldShowChangeNotification,
} from '../../common/types.tsx';
import { useFetchEntitlement } from '../subscribe-to-addon-modal/util.tsx';

export const useFetchBacUrl = () => {
	const { fetch: fetchEntitlementDetails, data, isLoading } = useFetchEntitlement();
	const environment = useEnvironment();

	useEffect(() => {
		fetchEntitlementDetails();
	}, [fetchEntitlementDetails]);

	let redirectUrl: string | null = null;
	if (data) {
		const transactionAccountId = data.transactionAccountId;
		let entitlementId = data.entitlementId;
		if (entitlementId) {
			const parts = entitlementId.split('/');
			entitlementId = parts[parts.length - 1];
		}

		redirectUrl = getAdminHubUrl({
			suffix: `billing/${transactionAccountId}/entitlement/${entitlementId}`,
			environment,
		});
	}

	return { redirectUrl, isLoading };
};

export const useSelectedTab = (): FeatureTypes => {
	const [selectedTab] = useQueryParam('feature');

	if (selectedTab === 'virtual-agent') {
		return 'virtual-agent';
	}

	return 'assets';
};

export const getCbpEnforcementDate = () => {
	// The CBP enforcement date is October 16, 2024, Timezone: America/Los_Angeles (PDT)
	return new Date('2024-10-16T00:00:00-07:00');
};

export const useGetWarrantyPeriodEndDateFromNextBillingStartDate = (date?: number): Date | null => {
	if (!date) {
		return null;
	}
	// This is used alonside isWarrantyCustomer field & if a customer is a warranty customer & then the next biling date would be the end of warranty period
	const nextBillingDate = new Date(date);
	return nextBillingDate;
};

const getMonthlyWarrantyPeriodEndDate = (date: number): Date => {
	const currentBillingDate = new Date(date);
	const cbpEnforcementDate = getCbpEnforcementDate();
	while (currentBillingDate >= cbpEnforcementDate) {
		currentBillingDate.setMonth(currentBillingDate.getMonth() - 1);
	}
	currentBillingDate.setMonth(currentBillingDate.getMonth() + 1);
	return currentBillingDate;
};

const getAnnualWarrantyPeriodEndDate = (date: number): Date => {
	const currentBillingDate = new Date(date);
	const cbpEnforcementDate = getCbpEnforcementDate();
	while (currentBillingDate >= cbpEnforcementDate) {
		currentBillingDate.setFullYear(currentBillingDate.getFullYear() - 1);
	}
	currentBillingDate.setFullYear(currentBillingDate.getFullYear() + 1);
	return currentBillingDate;
};

export const useGetGracePeriodEndDateFromNextBillingPeriodStartDate = (
	date?: number,
	billingType?: string,
): string | null => {
	if (!date) return null;
	// we first need to calculate end of warranty period for the site & then get the end of grace period date according to the billingType
	if (billingType === 'monthly') {
		const monthlyWarrantyPeriodEndDate = getMonthlyWarrantyPeriodEndDate(date);
		return new Date(
			monthlyWarrantyPeriodEndDate.setFullYear(monthlyWarrantyPeriodEndDate.getFullYear() + 1),
		).toString();
	}

	const annualWarrantyEndDate = getAnnualWarrantyPeriodEndDate(date);
	return new Date(
		annualWarrantyEndDate.setFullYear(annualWarrantyEndDate.getFullYear() + 1),
	).toString();
};

export const useGetGracePeriodEndDateFromEndOfWarrantyPeriod = (
	date?: string,
	billingType?: string,
): string | null => {
	if (!date || !billingType) {
		return null;
	}

	const warrantyPeriodEndDate = new Date(date);
	return new Date(
		warrantyPeriodEndDate.setFullYear(warrantyPeriodEndDate.getFullYear() + 1),
	).toString();
};

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

export const cbpEnforcementDaysRemaining = (enforcementDate: string): number => {
	const enforcementDateInMs = new Date(enforcementDate).getTime();
	const currentDateInMs = Date.now();
	const daysDifference = Math.floor(
		(enforcementDateInMs - currentDateInMs) / MILLISECONDS_IN_A_DAY,
	);
	return daysDifference;
};

export const useShouldShowChangeNotification = ({
	enforcementDate,
	isWarrantyCustomer,
	isGracedCustomer,
}: ChangeNotificationProps): ShouldShowChangeNotification => {
	const isJiraAdmin = useIsAdmin();
	const cbpEnforcementDaysRemainingValue = fg('cbp_change_related_notification_banners_and_flags')
		? cbpEnforcementDaysRemaining(enforcementDate)
		: -1;

	if (!isJiraAdmin || !fg('cbp_change_related_notification_banners_and_flags')) {
		return {
			shouldShowFlag: false,
			shouldShowBanner: false,
			cbpEnforcementDaysRemaining: cbpEnforcementDaysRemainingValue,
		};
	}

	if (isGracedCustomer || isWarrantyCustomer) {
		const isWithin30Days =
			cbpEnforcementDaysRemainingValue <= 30 && cbpEnforcementDaysRemainingValue >= 1;
		const isWithin90Days =
			cbpEnforcementDaysRemainingValue <= 90 && cbpEnforcementDaysRemainingValue >= 1;
		if (isWithin30Days) {
			return {
				shouldShowFlag: true,
				shouldShowBanner: true,
				cbpEnforcementDaysRemaining: cbpEnforcementDaysRemainingValue,
			};
		}
		if (isWithin90Days) {
			return {
				shouldShowFlag: true,
				shouldShowBanner: false,
				cbpEnforcementDaysRemaining: cbpEnforcementDaysRemainingValue,
			};
		}
	}

	return {
		shouldShowFlag: false,
		shouldShowBanner: false,
		cbpEnforcementDaysRemaining: cbpEnforcementDaysRemainingValue,
	};
};
