import React from 'react';
import { ErrorFlag, SuccessFlag } from '@atlassian/jira-flags';
import type { FlagCustom } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import type { CompletedAttributeSetUniqueTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import {
	toSchemaId,
	toCmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { createObjectTypeUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import type { BaseFlagProps } from '../types.tsx';
import messages from './messages.tsx';

export type CompletedFlagSetAttributeUniqueProps = {
	task: CompletedAttributeSetUniqueTask;
} & BaseFlagProps;

export const CompletedFlagAttributeSetUnique = ({
	task,
	flagProps,
	onDismissed,
}: CompletedFlagSetAttributeUniqueProps) => {
	const { formatMessage } = useIntl();
	return task.resultPayload.hasError ? (
		<ErrorFlag
			{...flagProps}
			title={formatMessage(messages.errorFlagTitle)}
			id={task.taskId}
			description={task.resultPayload.failureReason}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-attribute-set-unique.error-flag"
			messageId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-attribute-set-unique.error-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.goToObjectTypeButtonLabel),
					href: createObjectTypeUrl(
						toSchemaId(task.resultPayload.objectType.objectSchemaId),
						toCmdbObjectTypeId(task.resultPayload.objectType.id),
					),
				},
			]}
		/>
	) : (
		<SuccessFlag
			{...flagProps}
			title={formatMessage(messages.successFlagTitle)}
			id={task.taskId}
			description={formatMessage(messages.successFlagDescription, {
				attributeName: task.resultPayload.attributeName,
			})}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-attribute-set-unique.success-flag"
			messageId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-attribute-set-unique.success-flag"
			messageType="transactional"
		/>
	);
};

export const attributeSetUniqueFlagHandler = (
	props: Omit<CompletedFlagSetAttributeUniqueProps, 'flagProps'>,
): FlagCustom => ({
	id: props.task.taskId,
	render: (flagProps) => <CompletedFlagAttributeSetUnique {...props} flagProps={flagProps} />,
});
