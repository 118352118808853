import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notFoundDocumentTitleJsm: {
		id: 'servicedesk-spa-commons.common.utils.errors.not-found-document-title-jsm',
		defaultMessage: 'Project not found - Service project',
		description: 'The document title to show when there is an error',
	},
	notFoundTitle: {
		id: 'servicedesk-spa-commons.common.utils.errors.not-found-title',
		defaultMessage: "This project isn't available",
		description: "Title of the error message when the project can't be found",
	},
	notFoundDescription: {
		id: 'servicedesk-spa-commons.common.utils.errors.not-found-description',
		defaultMessage: 'It may have been deleted or your permissions may have changed',
		description: "Description of the error message when the project can't be found",
	},
	notFoundAction: {
		id: 'servicedesk-spa-commons.common.utils.errors.not-found-action',
		defaultMessage: 'View a list of all projects',
		description: "Call to action when the project can't be found",
	},
	genericDocumentTitleJsm: {
		id: 'servicedesk-spa-commons.common.utils.errors.generic-document-title-jsm',
		defaultMessage: 'Error - Service project',
		description: 'The document title to show when there is an error',
	},
	genericErrorTitle: {
		id: 'servicedesk-spa-commons.common.utils.errors.generic-error-title',
		defaultMessage: 'An error occurred',
		description: 'Title of the error message for a generic error',
	},
	genericErrorDescription: {
		id: 'servicedesk-spa-commons.common.utils.errors.generic-error-description',
		defaultMessage: 'An error occurred while loading this page',
		description: 'Description of the error message for a generic error',
	},
	serviceHubNotFoundDocumentTitleJsm: {
		id: 'servicedesk-spa-commons.common.utils.errors.servicehub-not-found-document-title-jsm',
		defaultMessage: 'Service hub not found - Service project',
		description: 'The document title to show when there is an error',
	},
	serviceHubNotFoundTitle: {
		id: 'servicedesk-spa-commons.common.utils.errors.servicehub-not-found-title',
		defaultMessage: "This service hub isn't available",
		description: "Title of the error message when the Service hub can't be found",
	},
	serviceHubNotFoundDescription: {
		id: 'servicedesk-spa-commons.common.utils.errors.servicehub-not-found-description',
		defaultMessage: 'It may have been deleted or your permissions may have changed',
		description: "Description of the error message when the customer hub can't be found",
	},
	serviceHubNotFoundAction: {
		id: 'servicedesk-spa-commons.common.utils.errors.servicehub-not-found-action',
		defaultMessage: 'View a list of all service hubs',
		description: "Call to action when the service hub can't be found",
	},
	customerHubNotFoundDocumentTitleJsm: {
		id: 'servicedesk-spa-commons.common.utils.errors.customer-hub-not-found-document-title-jsm',
		defaultMessage: 'Customer hub not found - Service project',
		description: 'The document title to show when there is an error',
	},
	customerHubNotFoundTitle: {
		id: 'servicedesk-spa-commons.common.utils.errors.customer-hub-not-found-title',
		defaultMessage: "This customer hub isn't available",
		description: "Title of the error message when the Customer hub can't be found",
	},
	customerHubNotFoundAction: {
		id: 'servicedesk-spa-commons.common.utils.errors.customer-hub-not-found-action',
		defaultMessage: 'View a list of all customer hubs',
		description: "Call to action when the customer hub can't be found",
	},
});
