import type { CmdbLimitState } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/global-config.tsx';
import { NONE } from '../constants.tsx';
import type { ObjectUsageLocation, ObjectUsageInfo, UserRole, BillingPeriod } from '../types.tsx';

export const determineThreshold = ({
	objectCount,
	objectLimit,
}: {
	objectCount: number;
	objectLimit: number;
}): ObjectUsageInfo['type'] => {
	if (objectCount >= objectLimit) {
		return 'limit-reached';
	}
	if (objectCount >= objectLimit * 0.9) {
		return '90-percent';
	}
	if (objectCount >= objectLimit * 0.8) {
		return '80-percent';
	}
	return 'none';
};

/**
 * Common logic for determining what messaging to show to users related to object limits
 */
export const determineObjectUsageInfo = ({
	isJiraAdmin,
	objectCount,
	objectLimit,
	billingPeriod,
	location,
	limitState,
	isDismissed,
}: {
	isJiraAdmin: boolean;
	objectCount: number;
	objectLimit: number;
	billingPeriod: BillingPeriod;
	location: ObjectUsageLocation;
	limitState?: CmdbLimitState;
	isDismissed: boolean;
}): ObjectUsageInfo => {
	const userRole: UserRole = isJiraAdmin ? 'admin' : 'agent';
	const objectsRemaining = Math.max(objectLimit - objectCount, 0);

	const threshold = determineThreshold({ objectCount, objectLimit });
	const limitReachedUsageInfo = {
		type: threshold,
		userRole,
		billingPeriod,
		objectsRemaining,
	};

	switch (threshold) {
		case 'limit-reached':
			switch (location) {
				case 'create-object':
					if (limitState === 'notify-only') return NONE;
					return limitReachedUsageInfo;

				case 'import-data-modal':
					return limitReachedUsageInfo;

				case 'assets-feature-usage-dashboard':
				case 'limits-banner':
				default:
					if (isDismissed) return NONE;
					return limitReachedUsageInfo;
			}
		case '90-percent':
			switch (location) {
				case 'import-data-modal':
					return limitReachedUsageInfo;

				case 'assets-feature-usage-dashboard':
				case 'limits-banner':
				case 'create-object':
				default:
					if (isDismissed) return NONE;
					return limitReachedUsageInfo;
			}
		case '80-percent': {
			if (isDismissed) return NONE;
			switch (location) {
				case 'assets-feature-usage-dashboard':
				case 'limits-banner':
				case 'create-object':
					if (userRole === 'agent') {
						// 80% not shown for agents in object create + limits-banner
						return NONE;
					}
					return limitReachedUsageInfo;

				case 'import-data-modal':
					// 80% not shown for any userRole in import data modal
					return NONE;

				default:
					return NONE;
			}
		}
		case 'none':
			return NONE;
		default:
			return NONE;
	}
};
