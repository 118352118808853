import {
	OPERATION_TYPE_ATTRIBUTE_SET_LABEL,
	OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE,
	OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE,
	OPERATION_TYPE_BULK_DELETE,
	OPERATION_TYPE_BULK_EDIT,
	OPERATION_TYPE_BULK_EXPORT,
	OPERATION_TYPE_BULK_QR_CODE,
	type ResultsModalTask,
	type Task,
} from '../../types/task.tsx';

const throwUnknownOperationType = (operationType: never) => {
	throw new Error(`Unknown operation type: ${operationType}`);
};

export const matchTaskByOperationType = <T extends Task, R>(
	task: T,
	handlers: {
		[operationType in Task['operationType']]: (
			task: Extract<T, { operationType: operationType }>,
		) => R;
	},
): R => {
	const operationType = task.operationType;
	switch (operationType) {
		case OPERATION_TYPE_BULK_EDIT:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkEdit"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "BulkEdit"; }>'. Type 'BaseTask & { operationType: "BulkEdit"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "BulkEdit"; }>'
			return handlers.BulkEdit(task);
		case OPERATION_TYPE_BULK_DELETE:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkDelete"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'. Type 'BaseTask & { operationType: "BulkDelete"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'
			return handlers.BulkDelete(task);
		case OPERATION_TYPE_BULK_EXPORT:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkExport"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "BulkExport"; }>'. Type 'BaseTask & { operationType: "BulkExport"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "BulkExport"; }>'
			return handlers.BulkExport(task);
		case OPERATION_TYPE_BULK_QR_CODE:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkQrCode"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "BulkQrCode"; }>'. Type 'BaseTask & { operationType: "BulkQrCode"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "BulkQrCode"; }>'
			return handlers.BulkQrCode(task);
		case OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "SetAttributeAsUnique"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsUnique"; }>'. Type 'BaseTask & { operationType: "SetAttributeAsUnique"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsUnique"; }>'
			return handlers.SetAttributeAsUnique(task);
		case OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "SetAttributeAsNonUnique"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsNonUnique"; }>'. Type 'BaseTask & { operationType: "SetAttributeAsNonUnique"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsNonUnique"; }>'
			return handlers.SetAttributeAsNonUnique(task);
		case OPERATION_TYPE_ATTRIBUTE_SET_LABEL:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "SetAttributeAsLabel"; }>'. Type 'Task' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsLabel"; }>'. Type 'BaseTask & { operationType: "SetAttributeAsLabel"; } & { status: "Queued" | "InProgress"; }' is not assignable to type 'Extract<T, { operationType: "SetAttributeAsLabel"; }>'
			return handlers.SetAttributeAsLabel(task);
		default:
			return throwUnknownOperationType(operationType);
	}
};

export const matchTaskOperationType = <T,>(
	operationType: Task['operationType'],
	handlers: {
		[operationType in Task['operationType']]: () => T;
	},
): T => {
	switch (operationType) {
		case OPERATION_TYPE_BULK_EDIT:
			return handlers.BulkEdit();
		case OPERATION_TYPE_BULK_DELETE:
			return handlers.BulkDelete();
		case OPERATION_TYPE_BULK_EXPORT:
			return handlers.BulkExport();
		case OPERATION_TYPE_BULK_QR_CODE:
			return handlers.BulkQrCode();
		case OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE:
			return handlers.SetAttributeAsUnique();
		case OPERATION_TYPE_ATTRIBUTE_SET_NON_UNIQUE:
			return handlers.SetAttributeAsNonUnique();
		case OPERATION_TYPE_ATTRIBUTE_SET_LABEL:
			return handlers.SetAttributeAsLabel();
		default:
			return throwUnknownOperationType(operationType);
	}
};

export const matchResultsModalTaskByOperationType = <T extends ResultsModalTask, R>(
	task: T,
	handlers: {
		[operationType in ResultsModalTask['operationType']]: (
			task: Extract<T, { operationType: operationType }>,
		) => R;
	},
): R => {
	const operationType = task.operationType;
	switch (operationType) {
		case OPERATION_TYPE_BULK_EDIT:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkEdit"; }>'. Type 'ResultsModalTask' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'. Type 'BaseTask & OperationType<"BulkDelete"> & Status<"Completed"> & ResultPayload<ObjectResultPayload>' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'.
			return handlers.BulkEdit(task);
		case OPERATION_TYPE_BULK_DELETE:
			// Unfortunately typescript doesn't support narrowing on generics, but this is valid as T extends Task
			// @ts-expect-error - TS2345 - Argument of type 'T' is not assignable to parameter of type 'Extract<T, { operationType: "BulkDelete"; }>'. Type 'ResultsModalTask' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'. Type 'BaseTask & OperationType<"BulkDelete"> & Status<"Completed"> & ResultPayload<ObjectResultPayload>' is not assignable to type 'Extract<T, { operationType: "BulkDelete"; }>'.
			return handlers.BulkDelete(task);
		default:
			return throwUnknownOperationType(operationType);
	}
};
