import React, { type ReactElement, useMemo, useContext } from 'react';
import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';
import UFOInteractionIDContext from '@atlaskit/react-ufo/interaction-id-context';
import {
	addError,
	addErrorToAll,
	getActiveInteraction,
	removeHoldByID,
} from '@atlaskit/react-ufo/interaction-metrics';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { getActiveTrace } from '@atlaskit/react-ufo/experience-trace-id-context';
import { ErrorHashAndTraceId } from '@atlassian/jira-errors-handling/src/ui/error-hash-and-trace-id/ErrorHashAndTraceId.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import ErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error/index.tsx';
import SearchErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/search-error/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export const ProjectNotFound = () => {
	const { formatMessage } = useIntl();
	const projectsPath = '/jira/projects';

	return (
		<>
			<DocumentTitle title={formatMessage(messages.notFoundDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(SearchErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.notFoundTitle)}
				description={formatMessage(messages.notFoundDescription)}
				primaryAction={<Link to={projectsPath}>{formatMessage(messages.notFoundAction)}</Link>}
			/>
		</>
	);
};

export const ServiceHubNotFound = () => {
	const projectsPath = '/jira/projects';
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle
				title={formatMessage(
					fg('jcs_project_type_m3')
						? messages.customerHubNotFoundDocumentTitleJsm
						: messages.serviceHubNotFoundDocumentTitleJsm,
				)}
			/>
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(SearchErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(
					fg('jcs_project_type_m3')
						? messages.customerHubNotFoundTitle
						: messages.serviceHubNotFoundTitle,
				)}
				description={formatMessage(messages.serviceHubNotFoundDescription)}
				primaryAction={
					<Link to={projectsPath}>
						{formatMessage(
							fg('jcs_project_type_m3')
								? messages.customerHubNotFoundAction
								: messages.serviceHubNotFoundAction,
						)}
					</Link>
				}
			/>
		</>
	);
};

export const GenericError = () => {
	const interactionId = useContext(UFOInteractionIDContext).current;
	const labelStack = useContext(UFOInteractionContext)?.labelStack;

	const traceId = useMemo(() => {
		const traceContext = getActiveTrace();

		return traceContext?.traceId;
	}, []);

	const { formatMessage } = useIntl();
	let description: ReactElement | string = formatMessage(messages.genericErrorDescription);

	if (traceId) {
		const error = new Error('An error occurred while loading this page');
		description = (
			<>
				<div>{description}</div>
				<Box xcss={styles}>
					<ErrorHashAndTraceId error={error} traceId={traceId} />
				</Box>
			</>
		);

		if (fg('add_ufo_error_in_service_desk_genericerror')) {
			const name = 'serviceDeskGenericError';
			if (interactionId) {
				addError(interactionId, name, labelStack ?? null, error.name, error.message, error.stack);
				// NOTE: to account for awaiting BM3 TTI in UFO code, however we don't need to wait for BM3 TTI when there is an error
				// https://atlassian.slack.com/archives/C01AY8132S3/p1736380314352749?thread_ts=1736377740.484469&cid=C01AY8132S3
				const activeInteraction = getActiveInteraction();
				activeInteraction?.holdActive.forEach((_, holdId) => {
					removeHoldByID(activeInteraction.id, holdId);
				});
			} else {
				addErrorToAll(name, labelStack ?? null, error.name, error.message, error.stack);
			}
		}
	}
	return (
		<>
			<DocumentTitle title={formatMessage(messages.genericDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(ErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.genericErrorTitle)}
				description={description}
			/>
		</>
	);
};

const styles = xcss({
	marginTop: 'space.100',
});
