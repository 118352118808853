import {
	OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE,
	STATUS_QUEUED,
	type TaskId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { mergeTasks } from '../../../common/utils/merge-tasks/index.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { showSuccessFlag } from '../show-success-flag/index.tsx';
import { startTaskPolling } from '../start-task-polling/index.tsx';

export type AddSetAttributeUniqueTaskProps = {
	taskId: TaskId;
	attributeName: string;
};

export const addAttributeSetUniqueTask =
	({ taskId, attributeName }: AddSetAttributeUniqueTaskProps): CmdbAsyncActionStoreAction<void> =>
	({ setState, getState, dispatch }) => {
		setState({
			tasks: mergeTasks(getState().tasks, [
				{
					taskId,
					operationType: OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE,
					status: STATUS_QUEUED,
				},
			]),
		});

		dispatch(
			showSuccessFlag({
				id: taskId,
				attributeName,
				action: OPERATION_TYPE_ATTRIBUTE_SET_UNIQUE,
			}),
		);
		dispatch(startTaskPolling());
	};
