import React, { useMemo } from 'react';
import { SuccessFlag, type FlagProps } from '@atlassian/jira-flags';
import type { FlagCustom } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { cmdbAsyncActionsResultsEntryPoint } from '@atlassian/jira-servicedesk-cmdb-async-actions-results-modal/src/entrypoint.tsx';
import type { CompletedBulkEditTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { CompletedFlagDescription } from '../completed-flag-description/index.tsx';
import { cmdbAsyncActionsResultsEntrypointModalProps } from '../constants.tsx';
import commonMessages from '../messages.tsx';
import type { BaseFlagProps } from '../types.tsx';
import { getCmdbAsyncActionsResultsModalEntrypointProps } from '../utils.tsx';
import messages from './messages.tsx';

export type CompletedFlagBulkEditProps = {
	task: CompletedBulkEditTask;
} & BaseFlagProps;

export const CompletedFlagBulkEdit = ({
	task,
	flagProps,
	onDismissed,
}: CompletedFlagBulkEditProps) => {
	const { formatMessage } = useIntl();
	const objectsProcessed = task.resultPayload.objectsProcessed;
	const flagActions: FlagProps['actions'] = useMemo(
		() => [
			{
				content: (
					<ModalEntryPointPressableTrigger
						entryPoint={cmdbAsyncActionsResultsEntryPoint}
						modalProps={cmdbAsyncActionsResultsEntrypointModalProps}
						entryPointProps={getCmdbAsyncActionsResultsModalEntrypointProps(task)}
						interactionName="cmdb-async-actions-results-modal"
						useInternalModal
					>
						{({ ref }) => (
							// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
							<span ref={ref}>{formatMessage(commonMessages.completedFlagViewReport)}</span>
						)}
					</ModalEntryPointPressableTrigger>
				),
				onClick: () => onDismissed(task.taskId),
			},
		],
		[formatMessage, onDismissed, task],
	);
	return (
		<SuccessFlag
			{...flagProps}
			title={formatMessage(messages.flagTitle, {
				objectsProcessed,
			})}
			id={task.taskId}
			description={
				<CompletedFlagDescription task={task}>
					{!task.resultPayload.hasError
						? formatMessage(commonMessages.completedFlagDescription)
						: formatMessage(commonMessages.completedFlagErrorDescription)}
				</CompletedFlagDescription>
			}
			messageId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-bulk-edit.success-flag"
			messageType="transactional"
			actions={task.resultPayload.hasError ? flagActions : undefined}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-bulk-edit"
		/>
	);
};

export const bulkEditFlagHandler = (
	props: Omit<CompletedFlagBulkEditProps, 'flagProps'>,
): FlagCustom => ({
	id: props.task.taskId,
	render: (flagProps) => <CompletedFlagBulkEdit {...props} flagProps={flagProps} />,
});
