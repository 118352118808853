import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import InformationIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import { Box, xcss, Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useInsightGlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { getFeatureUsagePageUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';

export const UsagePanel = () => {
	const { formatMessage, formatNumber } = useIntl();
	const [{ config }] = useInsightGlobalConfig();
	const [isHelpOpen, setIsHelpOpen] = useState(false); // help in this case is referring to the popup content and not the in product help article
	if (!config) {
		return null;
	}
	const { insightAdministrator, limitInfo } = config;
	const { objectCount } = limitInfo;

	const calculationInfo = formatMessage(messages.calculationInfo);
	const renderHelpContent = () => (
		<Box padding="space.200" xcss={helpBoxStyles}>
			<Text>{formatMessage(messages.excludedSchemasInfo)}</Text>
			<HelpPanelLink
				articleId="3sbbZSMOEhRWrOLZI4UNNs"
				appearance="link"
				spacing="none"
				onClick={() => setIsHelpOpen(false)}
			>
				<HelpText>{calculationInfo}</HelpText>
			</HelpPanelLink>
		</Box>
	);

	const content = (
		<>
			<Inline space="space.050" alignBlock="center">
				<Heading as="h2" size="xsmall">
					{formatMessage(messages.objects)}{' '}
				</Heading>
				<Popup
					isOpen={isHelpOpen}
					onClose={() => setIsHelpOpen(false)}
					placement="top-start"
					messageId="servicedesk-insight-landing-page.ui.usage-panel.popup"
					messageType="transactional"
					content={renderHelpContent}
					trigger={(triggerProps) => (
						<Button
							{...triggerProps}
							appearance="subtle"
							spacing="none"
							iconBefore={
								<InformationIcon
									label={formatMessage(messages.assetsUsageInfo)}
									spacing="spacious"
									color={isHelpOpen ? token('color.icon.selected') : token('color.icon.subtle')}
								/>
							}
							isSelected={isHelpOpen}
							onClick={() => setIsHelpOpen(!isHelpOpen)}
						/>
					)}
				/>
			</Inline>
			<Heading size="large">{formatNumber(objectCount)}</Heading>
			{insightAdministrator && (
				<div>
					<a href={getFeatureUsagePageUrl()} target="_blank" rel="noopener noreferrer">
						<Inline space="space.050" alignBlock="center">
							<Text color="inherit">{formatMessage(messages.viewDetailedUsage)}</Text>
							<ShortcutIcon LEGACY_size="small" label="" />
						</Inline>
					</a>
				</div>
			)}
		</>
	);

	if (fg('assets_as_an_app_v2')) {
		return <Box xcss={containerStyles}>{content}</Box>;
	}
	return <Container>{content}</Container>;
};

const helpBoxStyles = xcss({
	maxWidth: '210px',
});
const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.150',
	marginBlock: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.div({
	whiteSpace: 'normal',
	textAlign: 'start',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	gap: token('space.150'),
	flexDirection: 'column',
	marginTop: token('space.200'),
	marginBottom: token('space.300'),
});
