import {
	type ApplicationKey,
	PRODUCT_DISCOVERY,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import { projectToApplicationKey } from '../../common/index.tsx';
import type { SupportedProject } from '../../common/types.tsx';

/**
 * Gets the HAMS product key for the specified applicationKey / product. Useful for Commerce GraphQL API calls.
 * @param applicationKey - the product that you want to fetch the HAMS product key for, e.g: 'SOFTWARE' aka 'jira-software'
 */
export const getHamsProductKey = (applicationKey: ApplicationKey) => {
	// JPD key does not have '.ondemand'
	if (applicationKey === PRODUCT_DISCOVERY) {
		return applicationKey;
	}

	return `${applicationKey}.ondemand`;
};

/**
 * Gets the HAMS product key for the specified applicationKey / product. Useful for Commerce GraphQL API calls.
 * WARNING: To support spork, this function will treat CORE projects as SOFTWARE projects.
 * @param project The project that you want to get the HAMS product key for
 * @returns hamsProductKey
 */
export const getHamsProductKeyForProject = (project: SupportedProject) => {
	const application: ApplicationKey = projectToApplicationKey(project);
	return getHamsProductKey(application);
};
