import React from 'react';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import type { FlagConfiguration } from '@atlassian/jira-flags/src/services/types.tsx';
import messages from '../../../common/messages.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

type Props = {
	id: string;
	objectCount?: string;
	attributeName?: string;
	action: TaskOperationType;
};

export const showSuccessFlag =
	({ id, objectCount, attributeName, action }: Props): CmdbAsyncActionStoreAction<void> =>
	(_, { showFlag, formatMessage }) => {
		const baseFlag: Pick<
			FlagConfiguration,
			'messageId' | 'messageType' | 'id' | 'icon' | 'type' | 'description'
		> = {
			messageId:
				'servicedesk-cmdb-async-actions.controllers.actions.show-success-flag.base-flag.info.show-flag.info.success',
			messageType: 'transactional',
			id,
			icon: <RefreshIcon spacing="spacious" label="" />,
			type: 'info',
			description: formatMessage(messages.submitSuccessFlagDescription),
		};

		matchTaskOperationType(action, {
			BulkExport: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitSuccessFlagTitleBulkExport, { objectCount }),
				}),
			BulkQrCode: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitSuccessFlagTitleBulkQrCode, { objectCount }),
				}),
			BulkEdit: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitSuccessFlagTitleBulkEdit, { objectCount }),
				}),
			BulkDelete: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.submitSuccessFlagTitleBulkDelete, { objectCount }),
				}),
			SetAttributeAsUnique: () =>
				showFlag({
					...baseFlag,
					title: formatMessage(messages.addSetAttributeUniqueSuccessFlagTitle),
					description: formatMessage(messages.addSetAttributeUniqueFlagDescription, {
						attributeName,
					}),
				}),
			SetAttributeAsNonUnique: () => undefined,
			SetAttributeAsLabel: () => undefined,
		});
	};
