// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { INTERCOM_API_BASE } from './constants.tsx';
import { intercomScript } from './intercom-script.tsx';
import type { IntercomWindow, RawIntercomProps } from './types.tsx';
import { isSSR } from './utils.tsx';

const initialState = {
	isIntercomInitialized: false,
	isBooted: false,
};

export const IntercomContextProvider = createContainer();

const Store = createStore({
	initialState,
	actions: {
		initialize:
			({ appId }: { appId: string }) =>
			({ getState, setState }) => {
				if (!getState().isIntercomInitialized && !isSSR) {
					intercomScript(appId);
					setState({
						isIntercomInitialized: true,
					});
				}
			},
		boot:
			(userData: RawIntercomProps, appId: string) =>
			({ getState, setState }) => {
				const w: IntercomWindow = window;
				if (
					!getState().isBooted &&
					getState().isIntercomInitialized &&
					w.Intercom &&
					typeof w.Intercom === 'function'
				) {
					const intercomSettings = {
						api_base: INTERCOM_API_BASE,
						app_id: appId,
						...userData,
					};
					w.intercomSettings = intercomSettings;
					w.Intercom('boot', intercomSettings);
					setState({
						isBooted: true,
					});
				}
			},
		shutdown:
			() =>
			({ getState, setState }) => {
				if (!getState().isBooted) return;

				const w: IntercomWindow = window;
				if (w.Intercom) {
					w.Intercom('shutdown');
					delete w.intercomSettings;
					setState({
						isBooted: false,
					});
				}
			},
		trackEvent:
			(event: string, metaData?: object) =>
			({ getState }) => {
				const w: IntercomWindow = window;
				if (getState().isIntercomInitialized && w.Intercom && typeof w.Intercom === 'function') {
					if (metaData) {
						w.Intercom('trackEvent', event, metaData);
					} else {
						w.Intercom('trackEvent', event);
					}
				}
			},
		onShow:
			(onshow: () => void) =>
			({ getState }) => {
				const w: IntercomWindow = window;
				if (getState().isIntercomInitialized && w.Intercom && typeof w.Intercom === 'function') {
					w.Intercom('onShow', onshow);
				}
			},
	},
	name: 'intercomStateStore',
	containedBy: IntercomContextProvider,
});

export const useIntercom = createHook(Store);
