/** @jsx jsx */
import React, {
	type Ref,
	forwardRef,
	type AnchorHTMLAttributes,
	type ComponentPropsWithRef,
	type HTMLAttributes,
} from 'react';
import { css as css2, jsx } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled, jira/restricted/styled-components-migration -- Ignored via go/DSP-18766
import styled, { css, type StyledComponentClass } from 'styled-components';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { borderRadius, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getUniqueIconUrl } from '@atlassian/jira-servicedesk-insight-icon/src/common/utils.tsx';
import { UnstyledInsightIcon } from '@atlassian/jira-servicedesk-insight-icon/src/ui/index.tsx';
import type {
	CmdbAvatar,
	CmdbIcon,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';
import type { CmdbObjectId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { createObjectDetailUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import messages from '../../common/messages.tsx';
import { ReadViewContainer, PlaceholderSpacer } from '../../common/ui/styled.tsx';

export type ReferencedObjectContentType = {
	label: string;
	avatar?: CmdbAvatar;
	icon?: CmdbIcon;
};

const TooltipTag = forwardRef((props, ref: Ref<HTMLDivElement>) => (
	<StyledTooltipTag ref={ref} {...props} />
));

export const ReferencedObjectContent = ({ label, avatar, icon }: ReferencedObjectContentType) => (
	<>
		{icon && <UnstyledInsightIcon iconUrl={getUniqueIconUrl(icon)} size="xsmall" label={label} />}
		{avatar && (
			<UnstyledInsightIcon
				mediaClientConfig={avatar.mediaClientConfig}
				iconUrl={avatar.url48}
				size="xsmall"
				label=""
			/>
		)}
		{/* @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'keyof IntrinsicElements | ComponentType<AllHTMLAttributes<HTMLElement> & { ref: Ref<HTMLElement>; }> | undefined'. */}
		<Tooltip content={label} tag={TooltipTag} position="top">
			<ReferencedObjectLabel>{label}</ReferencedObjectLabel>
		</Tooltip>
	</>
);

export type ReferencedObjectCreateViewType = {
	label: string;
	icon?: CmdbIcon;
};

export const ReferencedObjectCreateView = (props: ReferencedObjectCreateViewType) => (
	<PlainText forceMaxWidth={false}>
		<ReferencedObjectContent label={props.label} icon={props.icon} />
	</PlainText>
);

export type ReferencedObjectReadViewType = {
	id: CmdbObjectId;
	objectKey: string;
	label: string;
	avatar?: CmdbAvatar;
};

type Appearance = 'plain' | 'card';

const DEFAULT_APPEARANCE: Appearance = 'card';

export const ReferencedObjectReadView = ({
	appearance = DEFAULT_APPEARANCE,
	includeLink = true,
	onClick = noop,
	forceMaxWidth = true,
	...referencedObject
}: {
	appearance?: Appearance;
	includeLink?: boolean;
	onClick?: () => void;
	forceMaxWidth?: boolean;
} & ReferencedObjectReadViewType) => {
	const { id, label, avatar } = referencedObject;

	const content = <ReferencedObjectContent label={label} avatar={avatar} />;

	if (appearance === 'card') {
		return includeLink ? (
			<LozengeLink to={createObjectDetailUrl(id)} onClick={onClick} forceMaxWidth={forceMaxWidth}>
				{content}
			</LozengeLink>
		) : (
			<LozengeText forceMaxWidth={forceMaxWidth}>{content}</LozengeText>
		);
	}

	return <PlainText forceMaxWidth={forceMaxWidth}>{content}</PlainText>;
};

type Props = {
	referencedObjects: ReferencedObjectReadViewType[];
	appearance?: Appearance;
	includeLink?: boolean;
	showPlaceholderIfEmpty?: boolean;
};

export const ReferencedObjectsReadView = ({
	referencedObjects,
	appearance = DEFAULT_APPEARANCE,
	includeLink = true,
	showPlaceholderIfEmpty = true,
}: Props) => {
	const { formatMessage } = useIntl();
	const hasValues = referencedObjects.length > 0;
	const placeholderText = showPlaceholderIfEmpty
		? formatMessage(messages.placeholderNoneSelected)
		: null;

	return (
		<ReadViewContainer isPlaceholder={!hasValues}>
			{hasValues ? (
				<ReferencedObjectsContainer>
					{referencedObjects.map((referencedObject: ReferencedObjectReadViewType) => (
						<ReferencedObjectReadView
							// This needs to be ID because when creating a new object the key is a blank string breaking the map
							key={referencedObject.id}
							{...referencedObject}
							includeLink={includeLink}
							appearance={appearance}
						/>
					))}
				</ReferencedObjectsContainer>
			) : (
				<PlaceholderSpacer>{placeholderText}</PlaceholderSpacer>
			)}
		</ReadViewContainer>
	);
};

type LabelProps = {
	forceMaxWidth: boolean;
};

type LinkProps = ComponentPropsWithRef<typeof Link>;

const ellipsesTruncation = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const ellipsesTruncation2 = css2({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const maxWidth = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 19}px`,
});

const maxWidth2 = css2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 19}px`,
});

const lozengeStyles = css<LabelProps>(
	{
		display: 'inline-flex',
		alignItems: 'center',
		padding: `${token('space.025')} ${token('space.100')}`,

		backgroundColor: token('elevation.surface.raised'),

		color: token('color.text'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
		marginRight: token('space.050'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.forceMaxWidth ? maxWidth : null),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	ellipsesTruncation,
	{
		boxShadow: token('elevation.shadow.raised'),
	},
);

const lozengeStyles2 = css2({
	display: 'inline-flex',
	alignItems: 'center',
	paddingTop: token('space.025'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.100'),

	backgroundColor: token('elevation.surface.raised'),

	color: token('color.text'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	marginRight: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values,  @atlaskit/design-system/consistent-css-prop-usage
	...ellipsesTruncation2,
	boxShadow: token('elevation.shadow.raised'),
});

const tooltipStyles = css2({
	display: 'flex',
	minWidth: 0,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledTooltipTag = forwardRef(({ children, ...props }: any, ref: Ref<HTMLDivElement>) => (
	<div css={tooltipStyles} {...props} ref={ref}>
		{children}
	</div>
));

const lozengeLinkStyles = css2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	...lozengeStyles2,
	'&:focus, &:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		color: token('color.text'),
		textDecoration: 'none',
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed'),
	},
});

const LozengeLinkNew = ({
	children,
	...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps & LabelProps) => (
	<Link css={[lozengeLinkStyles, props.forceMaxWidth && maxWidth2]} {...props}>
		{children}
	</Link>
);

const referencedObjectsContainerStyles = css2({
	display: 'flex',
	flexWrap: 'wrap',
	gap: token('space.050'),
	maxWidth: '100%',
});

const ReferencedObjectsContainer = ({ children }: { children: React.ReactNode }) => (
	<div css={referencedObjectsContainerStyles}>{children}</div>
);

const LozengeTextNew = ({ children, ...props }: HTMLAttributes<HTMLDivElement> & LabelProps) => (
	<div css={[lozengeStyles2, props.forceMaxWidth && maxWidth2]} {...props}>
		{children}
	</div>
);

const plainTextStyles = css2({
	display: 'flex',
	alignItems: 'center',
	paddingTop: token('space.025'),
	paddingRight: 0,
	paddingBottom: token('space.025'),
	paddingLeft: 0,
	marginRight: token('space.050'),
	backgroundColor: 'inherit',
	color: token('color.text'),
});

const PlainTextNew = ({ children, ...props }: HTMLAttributes<HTMLDivElement> & LabelProps) => (
	<div css={[plainTextStyles, props.forceMaxWidth && maxWidth2]} {...props}>
		{children}
	</div>
);

const referencedObjectLabelStyles = css2({
	marginLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage
	...ellipsesTruncation2,
});

const ReferencedObjectLabelNew = ({ children }: { children: React.ReactNode }) => (
	<span css={referencedObjectLabelStyles}>{children}</span>
);

const LozengeLinkOld: StyledComponentClass<
	AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps & LabelProps,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
	// FIXME: type error passing result of `css` to `styled`
	// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
	// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
> = styled(Link)<LabelProps>`
	${lozengeStyles};

	&:focus,
	&:hover {
		background-color: ${token('color.background.neutral.subtle.hovered')};
		color: ${token('color.text')};
		text-decoration: none;
	}

	&:active {
		background-color: ${token('color.background.neutral.subtle.pressed')};
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression
const LozengeTextOld = styled.div<LabelProps>`
	${lozengeStyles};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ReferencedObjectLabelOld = styled.span(
	{
		marginLeft: token('space.050'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	ellipsesTruncation,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PlainTextOld = styled.div<LabelProps>(
	{
		display: 'flex',
		alignItems: 'center',
		padding: `${token('space.025')} 0`,
		marginRight: token('space.050'),
		backgroundColor: 'inherit',
		color: token('color.text'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.forceMaxWidth ? maxWidth : null),
);

const LozengeText = componentWithFG(
	'fly-2200-styled-component-migration',
	LozengeTextNew,
	LozengeTextOld,
);

const ReferencedObjectLabel = componentWithFG(
	'fly-2200-styled-component-migration',
	ReferencedObjectLabelNew,
	ReferencedObjectLabelOld,
);

const PlainText = componentWithFG(
	'fly-2200-styled-component-migration',
	PlainTextNew,
	PlainTextOld,
);

const LozengeLink = componentWithFG(
	'fly-2200-styled-component-migration',
	LozengeLinkNew,
	LozengeLinkOld,
);
