import React, { useCallback, type ReactNode, useState } from 'react';
import { Box, xcss, media, Anchor } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { IconButton } from '@atlaskit/button/new';
import { JiraBanner as Banner } from '@atlassian/jira-banner/src/ui/jira-banner.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	BANNER,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { ChangeNotificationProps } from '../../common/types.tsx';
import { useShouldShowChangeNotification } from '../utils/index.tsx';
import {
	LOCAL_STORAGE,
	CBP_CHANGE_NOTIFICATIONS_BANNER_DISMISS_KEY,
	CBP_CHANGE_NOTIFICATIONS_BANNER,
	CBP_CHANGE_NOTIFICATIONS_LINK,
} from '../../common/constants.tsx';
import messages from './messages.tsx';

export const CBPChangeNotificationBanner = (props: ChangeNotificationProps) => {
	const { shouldShowBanner } = useShouldShowChangeNotification(props);

	const { enforcementDate, shouldHaveOffset } = props;
	const { formatDateOnly } = useDateTimeFormatter();

	const [isDismissed, setIsDismissed] = useState(
		LOCAL_STORAGE.get(CBP_CHANGE_NOTIFICATIONS_BANNER_DISMISS_KEY),
	);
	const { formatMessage } = useIntl();

	const onDismissClick = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'notificationBannerDismissed');
			LOCAL_STORAGE.set(CBP_CHANGE_NOTIFICATIONS_BANNER_DISMISS_KEY, true);
			setIsDismissed(true);
		},
		[setIsDismissed],
	);

	const getBannerContent = () => (
		<JSErrorBoundary
			id="cbpChangeNotificationBanner"
			packageName="servicedeskFeatureUsageCommon"
			teamName="Sequoia"
			fallback="unmount"
		>
			<ContextualAnalyticsData sourceType={BANNER} sourceName={CBP_CHANGE_NOTIFICATIONS_BANNER}>
				<FireScreenAnalytics />
				<Box xcss={outerBannerWrapper}>
					<Banner
						messageId="servicedesk-feature-usage-common.ui.change-notification-banner.banner"
						messageType="transactional"
						appearance="announcement"
					>
						<Box xcss={bannerContentWrapper}>
							{formatMessage(messages.changeNotificationBannerContent, {
								date: formatDateOnly(enforcementDate),
								link: (chunks: ReactNode) => (
									<Anchor
										href={CBP_CHANGE_NOTIFICATIONS_LINK}
										onClick={(_e, analyticsEvent) => {
											fireUIAnalytics(analyticsEvent, 'changeNotificationBannerLinkClicked');
										}}
										target="_blank"
									>
										{chunks}
									</Anchor>
								),
							})}
						</Box>
						<Box xcss={bannerCloseWrapper}>
							<IconButton
								appearance="subtle"
								onClick={onDismissClick}
								icon={(iconProps) => (
									<CrossIcon
										{...iconProps}
										LEGACY_size="medium"
										spacing="spacious"
										color={token('color.text.inverse')}
									/>
								)}
								label={formatMessage(messages.dismissBanner)}
							/>
						</Box>
					</Banner>
				</Box>
			</ContextualAnalyticsData>
		</JSErrorBoundary>
	);

	const getBannerContentWithOffset = () =>
		shouldHaveOffset ? (
			<Box xcss={layoutOffsetInner}>{getBannerContent()}</Box>
		) : (
			getBannerContent()
		);
	return shouldShowBanner && !isDismissed ? getBannerContentWithOffset() : null;
};

const outerBannerWrapper = xcss({
	position: 'relative',
	marginLeft: 'space.negative.100',
	marginRight: 'space.negative.100',
});

const bannerContentWrapper = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingRight: 'space.400',
	[media.above.sm]: {
		paddingRight: 'space.500',
	},
	paddingLeft: 'space.200',
});

const bannerCloseWrapper = xcss({
	position: 'absolute',
	right: 'space.150',
	top: '50%',
	transform: 'translateY(-50%)',
});

const layoutOffsetInner = xcss({
	marginLeft: 'space.negative.400',
	marginRight: 'space.negative.400',
});
