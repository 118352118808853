import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-attribute-set-unique.error-flag-title',
		defaultMessage: 'Action failed: Could not enable unique values',
		description: 'Flag title when Set Attribute Unique action is completed with errors',
	},
	successFlagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-attribute-set-unique.success-flag-title',
		defaultMessage: 'Action complete: Unique values enabled',
		description: 'Flag title when Set Attribute Unique action is completed successfully',
	},
	successFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-attribute-set-unique.success-flag-description',
		defaultMessage: 'No duplicates were found in {attributeName}.',
		description:
			'Flag description when Set Attribute Unique action is completed successfully where attributeName is the name of the attribute',
	},
	goToObjectTypeButtonLabel: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-attribute-set-unique.go-to-object-type-button-label',
		defaultMessage: 'Go to object type',
		description: 'Button text to go to the Object Type',
	},
});
