/**
 * @generated SignedSource<<9f7305687c8ce8ec1b98f473626f31a7>>
 * @relayHash 25f8184c97779d2d43780236d6281647
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c86a841e2ad16f9c8490c5cee0da211c49061b877306dc2d30213339f20781c9

import type { ConcreteRequest, Query } from 'relay-runtime';
export type utilEntitlementDetailsQuery$variables = {
  cloudId: string;
  productKey: string;
};
export type utilEntitlementDetailsQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly id: string;
        readonly transactionAccount: {
          readonly key: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type utilEntitlementDetailsQuery = {
  response: utilEntitlementDetailsQuery$data;
  variables: utilEntitlementDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productKey"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "utilEntitlementDetailsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "utilEntitlementDetailsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c86a841e2ad16f9c8490c5cee0da211c49061b877306dc2d30213339f20781c9",
    "metadata": {},
    "name": "utilEntitlementDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "90717ea6684da8c6418db45cb4538a79";

export default node;
