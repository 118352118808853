import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeNotificationBannerContent: {
		id: 'servicedesk-feature-usage-common.change-notification-banner.content',
		defaultMessage:
			'Starting {date}, consumption-based pricing will come into effect for Assets and the virtual service agent. <link> What this means for your site.</link>',
		description:
			'This is a banner messsage shown to the jira admins to inform them about the upcoming cbp enformcement',
	},
	dismissBanner: {
		id: 'servicedesk-feature-usage-common.change-notification-banner.dismiss',
		defaultMessage: 'Dismiss banner',
		description: 'Label text describing dismiss banner button',
	},
});
