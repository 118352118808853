import { getATLContextUrl } from '@atlassian/atl-context';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { OrgId } from '../types.tsx';

type AdminHubProps = {
	suffix: string;
	// TODO this is technically obsolete now as the atl-context figure out the env internally
	// however, this comes from TC and the library uses URL which might not be as foolproof.
	// Leaving this in case we need to pass it down in near future.
	// Hopefully we can remove this by the end of 2024
	environment: Environment;
	cloudId?: CloudId;
	orgId?: OrgId;
};

export const getAdminHubUrl = ({ suffix, cloudId, orgId }: AdminHubProps) => {
	const instanceUrl = getATLContextUrl('admin');

	if (orgId !== undefined && orgId !== '') {
		return `${instanceUrl}/o/${orgId}/${suffix}`;
	}

	if (cloudId) {
		return `${instanceUrl}/s/${cloudId}/${suffix}`;
	}

	return `${instanceUrl}/${suffix}`;
};
