import React, { useState, useCallback } from 'react';
import { styled as styled2 } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Box, xcss, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ReferencedObjectReadView } from '@atlassian/jira-servicedesk-insight-attribute-views/src/ui/referenced-object/index.tsx';
import { useLandingPageState } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/index.tsx';
import { InsightRecentObjectContainerSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-landing-container/InsightSchemaContainerSkeleton.tsx';

import { fg } from '@atlassian/jira-feature-gating';
import { useRecentObjectData } from '../hooks/use-recent-object-data/index.tsx';
import { ErrorContent } from './error/index.tsx';
import { messages } from './messages.tsx';
import type { ObjectListProps } from './types.tsx';

const ObjectList = (props: ObjectListProps) => {
	const { referencedObjects } = props;
	const { numberOfObjectsDisplayed } = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRecentObjectClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightRecentObjectLozenge');
	}, [createAnalyticsEvent]);

	if (referencedObjects.length === 0) {
		return <NoObjectsText>{formatMessage(messages.noObjectsToShow)}</NoObjectsText>;
	}

	return (
		<>
			{referencedObjects.slice(0, numberOfObjectsDisplayed).map((object) => (
				<ReferencedObjectReadView
					key={object.id}
					id={object.id}
					objectKey={object.objectKey}
					label={object.label}
					avatar={object.avatar}
					onClick={onRecentObjectClick}
				/>
			))}
		</>
	);
};

export const ObjectContainer = () => {
	const [numberOfObjectsDisplayed, setNumberOfObjectsDisplayed] = useState(5);
	const { formatMessage } = useIntl();
	const [{ recentObjectState: recentObjectStateOld }] = useLandingPageState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const recentObjectData = useRecentObjectData();

	const onViewMoreClick = useCallback(() => {
		setNumberOfObjectsDisplayed(numberOfObjectsDisplayed + 5);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightRecentObjectsViewMore');
	}, [numberOfObjectsDisplayed, createAnalyticsEvent]);

	const recentObjectState = fg('fly-2773-fix-schema-landing-page-multiple-loading')
		? recentObjectData
		: recentObjectStateOld;

	const displayFooter =
		recentObjectState.type === 'success' &&
		recentObjectState.recentObjects.length > numberOfObjectsDisplayed;

	return (
		<>
			{recentObjectState.type === 'loading' && <InsightRecentObjectContainerSkeleton />}

			{recentObjectState.type === 'error' && <ErrorContent />}

			{recentObjectState.type === 'success' && (
				<>
					<Box xcss={listHeaderStyles}>
						<Heading as="h2" size="xsmall">
							{formatMessage(messages.objectSchemas)}
						</Heading>
					</Box>
					{fg('assets_as_an_app_post_mvp') ? (
						<Stack space="space.100" alignInline="start" xcss={preventBoxShadowCutOff}>
							<ObjectList
								referencedObjects={recentObjectState.recentObjects}
								numberOfObjectsDisplayed={numberOfObjectsDisplayed}
							/>
						</Stack>
					) : (
						<ListWrapper>
							<ObjectList
								referencedObjects={recentObjectState.recentObjects}
								numberOfObjectsDisplayed={numberOfObjectsDisplayed}
							/>
						</ListWrapper>
					)}
				</>
			)}

			{displayFooter && (
				<Box xcss={listFooterStyles}>
					<Button appearance="link" spacing="none" onClick={onViewMoreClick}>
						{formatMessage(messages.viewMore)}
					</Button>
				</Box>
			)}
		</>
	);
};

const listHeaderStyles = xcss({
	marginBottom: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListWrapper = styled2.div({
	rowGap: token('space.100'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoObjectsText = styled2.span({
	color: token('color.text.subtle'),
	font: token('font.body.UNSAFE_small'),
	rowGap: 0,
});

const listFooterStyles = xcss({ marginTop: 'space.150' });

const preventBoxShadowCutOff = xcss({
	paddingBottom: 'space.025',
});
