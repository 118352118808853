import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

export const LOCAL_STORAGE = createLocalStorageProvider('jsm-cbp-common-info');
export const CBP_CHANGE_NOTIFICATIONS_BANNER_DISMISS_KEY =
	'cbp-change-notifications-banner-dismissed';
export const CBP_CHANGE_NOTIFICATIONS_FLAG_DISMISS_KEY = 'cbp-change-notifications-flag-dismissed';
export const CBP_CHANGE_NOTIFICATIONS_BANNER = 'CBPChangeNotificationBanner';
export const CBP_CHANGE_NOTIFICATIONS_FLAG = 'CBPChangeNotificationFlag';
export const CBP_CHANGE_NOTIFICATIONS_LINK =
	'https://support.atlassian.com/jira-service-management-cloud/docs/what-is-feature-usage-and-how-it-works/';
export const CCP = 'CCP';
export const HAMS = 'HAMS';
export const MONTHLY = 'MONTHLY';
export const ANNUAL = 'ANNUAL';
export const CBP_ENFORCEMENT_DATE = '2024-10-16';
